import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";

import Button from "../../Button";
import Field from "../../Forms/Field";
import { LocalizedContext } from "../../../services/LocalizedContextService";
import { changeUserPassword, getUserProfile } from "../../../services/salesforce/Account/user";

import "./styles.scss";
import { getAuthUser, userLogin } from "../../../services/salesforce/Auth";
import EcommerceContext from "../../../services/EcommerceContextService";
import { sendErrorEvents } from "../../../utils/sendErrorEvents";

const Password = () => {
  const user = getAuthUser();
  const { ctaLabels } = useContext(LocalizedContext).sanityLabels || {};
  const formLabels = useContext(LocalizedContext).sanityLabels?.formsLabels;
  const errorMessages = useContext(LocalizedContext).sanityLabels?.errorMessages;
  const [isEdit, setIsEdit] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [profileData, setProfileData] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { isUserAuthenticated, setUserAuthenticated } = React.useContext(EcommerceContext);

  useEffect(() => {
    const user = getAuthUser();
    const profileDataPromise = getUserProfile(user.rcid || "");
    profileDataPromise.then(data => {
      setProfileData(data);
    });
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm();

  const { mutate, isLoading, error } = useMutation({
    mutationFn: changeUserPassword,
    onSuccess: () => {
      profileData?.login && userLogin(profileData?.login, newPassword);
      setIsEdit(false);
      setErrorMessage("");
    }
  });

  const onSubmit = (data: any) => {
    setNewPassword(data.newPassword);
    mutate({ ...data, customerId: user.rcid });
  };

  const switchEditMode = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsEdit(!isEdit);
    reset();
  };

  const formFields: any = [
    {
      type: "password",
      name: "currentPassword",
      label: formLabels?.currentPassword,
      rules: {
        required: true
      },
      message: "Please, enter current password"
    },
    {
      type: "password",
      name: "newPassword",
      label: formLabels?.newPassword,
      placeholder: "must be 8 characters",
      rules: {
        required: true,
        pattern: {
          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$_%^&*]{8,}$/,
          message: errorMessages?.passwordRequirements
        },
        validate: (newPass: string) => {
          if (watch("currentPassword") === newPass) {
            return "New password is the same as current";
          }
        }
      },
      message: errorMessages?.passwordRequirements
    },
    {
      type: "password",
      name: "confirmpassword",
      label: formLabels?.confirmpassword || "Confirm Password",
      placeholder: "repeat password",
      rules: {
        required: true,
        validate: (val: string) => {
          if (watch("newPassword") != val) {
            return "Your password does not match";
          }
        },
        pattern: {
          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$_%^&*]{8,}$/,
          message: errorMessages?.passwordRequirements
        }
      },
      message: errorMessages?.passwordRequirements
    }
  ];

  const currentPasswordLabel = formLabels?.currentPassword || "current password";
  const handleSubmitClick = () => {
    sendErrorEvents(errors, formFields);
  };
  useEffect(() => {
    if (error) {
      const strtErr = "" + error;
      strtErr &&
        (strtErr.includes("400")
          ? setErrorMessage("Please, make sure you entered your current password correctly")
          : strtErr.includes("500")
          ? setErrorMessage("Something went wrong. Please, try again a bit later.")
          : setErrorMessage(strtErr));
    }
  }, [error]);

  return (
    <div className="change-password">
      {isEdit ? (
        <form onSubmit={handleSubmit(onSubmit)} className="change-password__wrapper">
          {formFields.map((field: any, index: number) => (
            <div className="row" key={index}>
              <Field key={field.name} {...field} registerInput={register} errors={errors} message={field.message} />
            </div>
          ))}

          {error && (
            <div className="row error-message">
              <p>{errorMessage}</p>
            </div>
          )}

          <div className="row change-password-buttons">
            <div className="col-12"></div>
            <Button variant="nonary" form="br-50" className="change-password__cancel" onClick={switchEditMode}>
              {ctaLabels?.cancel}
            </Button>
            <Button
              className="change-password__submit"
              type="submit"
              variant="nonary"
              form="br-50"
              disabled={isLoading}
              onClick={handleSubmitClick}
            >
              {ctaLabels?.save}
            </Button>
          </div>
        </form>
      ) : (
        <div className="change-password__wrapper">
          <div className="change-password__user-info">
            <div className="change-password__label">{currentPasswordLabel}</div>
            <div className="change-password__password">********</div>
          </div>
          <div className="row change-password-buttons">
            <Button variant="nonary" form="br-50" className="change-password__edit" onClick={switchEditMode}>
              {ctaLabels?.edit}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Password;
