import { getLocalStorageItem } from "../helper";
import axios from "axios";

const SALESFORCE_API_ENDPOINT =
  typeof window !== "undefined" &&
  (window.location.origin === "http://localhost:8000"
    ? "http://localhost:8888/.netlify/functions"
    : window?.location?.origin + "/.netlify/functions");

function getUserAccessToken() {
  const access_token = getLocalStorageItem("access_token");

  if (!access_token) {
    new Error("Access token is required to access this api");
  }
  return access_token;
}

export function getUserProfile(id: string): Promise<any> {
  const access_token = getUserAccessToken();

  return axios
    .get(`${SALESFORCE_API_ENDPOINT}/salesforce-get-profile/${id}`, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })
    .then(res => res.data);
}

export function updateUserProfile(data: any): Promise<any> {
  const access_token = getUserAccessToken();

  return axios
    .post(`${SALESFORCE_API_ENDPOINT}/salesforce-update-profile`, data, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })
    .then(res => res.data);
}

export function changeUserPassword(data: {
  customerId: string;
  currentPassword: string;
  newPassword: string;
}): Promise<any> {
  const access_token = getUserAccessToken();

  return axios
    .post(`${SALESFORCE_API_ENDPOINT}/salesforce-change-password`, data, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })
    .then(res => res.data);
}

export function updateUserAddress(data: {
  customerId: number;
  addressId: string;
  address1: string;
  address2: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  city: string;
  postalCode: string;
  stateCode: string;
  phone: string;
}) {
  const access_token = getUserAccessToken();

  return axios.patch(SALESFORCE_API_ENDPOINT + "/salesforce-address", data, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
}

export function createUserAddress(data: {
  customerId: number;
  addressId: string;
  address1: string;
  address2: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  city: string;
  postalCode: string;
  stateCode: string;
  phone: string;
}) {
  const access_token = getUserAccessToken();

  return axios.post(SALESFORCE_API_ENDPOINT + "/salesforce-address", data, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
}

export function getUserAddress(customerID: string) {
  const access_token = getUserAccessToken();

  return axios.get(SALESFORCE_API_ENDPOINT + "/salesforce-address/" + customerID, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
}

export function deleteUserAddress(customerID: string, addressId: string) {
  const access_token = getUserAccessToken();

  return axios.delete(`${SALESFORCE_API_ENDPOINT}/salesforce-address/${customerID}/${addressId}`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
}

export function getUserOrders(customerID: string) {
  const access_token = getUserAccessToken();

  return axios.get(SALESFORCE_API_ENDPOINT + "/salesforce-order/" + customerID, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
}
